import React from 'react'
import {
  Card,
  Alert,
  Button,
  Link,
} from 'evergreen-ui'

import { getGlobalState } from '../../hooks/use-global-state'

const orgMembershipLink = process.env.REACT_APP_ORG_MEMBERSHIP_LINK || ''

function MembershipStatus() {
  const { isMobile } = getGlobalState('deviceType')
  const { membership, links } = getGlobalState('user')
  let memberLink = links?.find((l) => l.shortName === 'member')?.shortUrl
  if (!memberLink?.includes('http')) memberLink = `http://${memberLink}`

  const isLapsed = membership.isCurrent === false

  return (
    <Card
      display="flex"
      alignItems="center"
      flexDirection="column"
      elevation={2}
      margin={isMobile ? 8 : 20}
      width="100%"
      height="auto"
      background="colors.gray50">
      {membership.isCurrent ? (
        <Alert
          appearance="card"
          width="100%"
          paddingLeft="25%"
          intent={'success'}
          title="Current Dues Member" />
      ) : (
        <Link
          width="100%"
          href={memberLink || orgMembershipLink}
          target="_blank">
          <Button
            width="100%"
            fontWeight={700}
            fontSize={isMobile ? 16 : 17}
            size="large"
            appearance="primary"
            intent={membership.isCurrent
              ? 'success'
              : isLapsed
                ? 'danger'
                : 'action'}>
            {membership.isCurrent
              ? 'Current Dues Member'
              : isLapsed
                ? 'Lapsed Dues Member - '
                  + `${isMobile ? 'Press' : 'Click'} to Renew!`
                : `${isMobile ? 'Press' : 'Click'} to become a Dues Member!`
            }
          </Button>
        </Link>
      )}
    </Card>
  )
}

export default MembershipStatus
