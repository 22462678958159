import React, { useState, useEffect } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  Pane,
  Card,
  Heading,
  Text,
  IconButton,
  DuplicateIcon,
  ErrorIcon,
  toaster,
  defaultTheme,
} from 'evergreen-ui'

import { getGlobalState } from '../../hooks/use-global-state'
import CardTitle from '../core/CardTitle'

const { colors } = defaultTheme

const LinkItem = ({ link }) => {
  const { isMobile } = getGlobalState('deviceType')
  const [hasError, setHasError] = useState(false)
  const { labelName, shortUrl } = link

  const handleCopiedLink = () => {
    toaster.notify(`${link.shortUrl} copied!`, {
      id: 'copied',
    })
  }

  useEffect(() => {
    if (!link.shortUrl) {
      setHasError(true)
    }
  }, [link.shortUrl])

  return (
    <Pane
      display="flex"
      alignItems="center"
      flexShrink={isMobile ? 0 : 1}
      flexWrap={isMobile ? 'wrap' : 'nowrap'}
      minWidth={isMobile ? '100%' : 'auto'}
      padding={10}
      width="auto">
      <Heading
        minWidth={!isMobile ? 200 : '100%'}
        marginBottom={isMobile ? 15 : 0}
        fontSize={17}
        fontWeight={700}
        color={colors.gray800}>
        {labelName}
      </Heading>

      <Pane
        display="flex"
        alignItems="center"
        padding={10}
        minHeight={50}
        width="100%"
        borderBottom={`2px solid ${colors.gray500}`}>
        {hasError ? (
          <>
            <ErrorIcon marginRight={10} size={16} color={colors.red300} />
            <Text fontSize="13px" color={colors.red300}>
              Problem getting link...
            </Text>
          </>
        ) : (
          <>
            <Heading
              id="shortUrl"
              fontSize={isMobile ? 15 : 17}
              color={colors.gray700}>
              {shortUrl}
            </Heading>

            <CopyToClipboard
              marginLeft="auto"
              text={shortUrl}
              onCopy={handleCopiedLink}>
              <IconButton
                icon={(
                  <DuplicateIcon
                    cursor="pointer"
                    color={colors.gray500} />
                )} />
            </CopyToClipboard>
          </>
        )}
      </Pane>
    </Pane>
  )
}

function ActivistLinks() {
  const { id: userId, links } = getGlobalState('user')
  const { isMobile } = getGlobalState('deviceType')
  return (
    <Card
      display="flex"
      alignItems="center"
      flexDirection="column"
      elevation={2}
      margin={isMobile ? 8 : 20}
      minWidth={isMobile ? 'auto' : 560}
      height="auto"
      background="colors.gray50">
      <CardTitle title="Links to Share" />

      <Pane width="100%" padding={20} paddingTop={10}>
        {links.map((link) => (
          <LinkItem key={link.id} link={link} userId={userId} />
        ))}
      </Pane>
    </Card>
  )
}

export default ActivistLinks
